import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';

const imageArray:string[] = ["ChongHaoEdited","weijyeEdited","hello3"];
const nameArray:string[]= ["Lew Chong Hao","Wang Wei Jye","Ong Kah Kit"];
const jobPostArray:string[]= ["CEO,Tech Lead","UI/UX designer","Software Engineer"];
const descriptionArray:string[] = ["Chong Hao is a self learned ruby on railist. He is also known as \"Daddy\" of the company who masterminds the entire operation of the company. He enjoys chinese traditional culture.",
                                   "Hey I am Wei Jye, the UX/UI designer in Vectorlux where i work on helping my team and customer to use our products in a peace of mind. My dream of making awesome UI is that users do not even need a user manual to use our products. What do I love about my work ? Is that people with many different stories and backgrounds are gathered here. By interacting with them and getting to know them, and just by that, we will be able to gain experience and thus will leads to self-reflection. By knowing oneself, a new path may open up.",
                                   "hello3"];
let counter;  

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.css']
})

export class OurTeamComponent implements OnInit {

  public chosen: Biography = {
    name:null,
    jobpost:null,
    description:null,
    image:null
  };
  public isLeftSelected: boolean = false;
  public isRightSelected: boolean = false;

  constructor() { }

  private resetState() {
    this.chosen.name = null;
    this.chosen.jobpost = null;
    this.chosen.description = null;
    this.chosen.image = null;
    this.isLeftSelected = false;
    this.isRightSelected = false;
  }

  public onButtonClick(letter: string) {
    this.resetState();
    switch (letter) {
      case 'left':
        counter--;
        if(counter<0){
          counter=2;
        }
        else if(counter>1){
          counter=0;
        }
        this.chosen.name=nameArray[counter];
        this.chosen.jobpost = jobPostArray[counter];
        this.chosen.description = descriptionArray[counter];
        this.chosen.image=imageArray[counter];
        break;
      case 'right':
        counter++;
        if(counter>2){
          counter=0;
        }
        this.chosen.name = nameArray[counter];
        this.chosen.jobpost = jobPostArray[counter];
        this.chosen.description = descriptionArray[counter];
        this.chosen.image=imageArray[counter];
        break;
    }

  }

  ngOnInit() {
    counter=0;
    this.chosen.name = nameArray[counter];
    this.chosen.jobpost = jobPostArray[counter];
    this.chosen.description = descriptionArray[counter];
    this.chosen.image=imageArray[counter];
  }

}

interface Biography {
  name: string;
  jobpost: string;
  description:string;
  image:string;
}
