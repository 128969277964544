import { NgModule } from '@angular/core';
import { RequestDemoDialogComponent } from './request-demo-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileEditorComponent } from './profile-editor/profile-editor.component';
@NgModule({
	declarations: [
		RequestDemoDialogComponent,
		ProfileEditorComponent,
	],
	imports: [
		ReactiveFormsModule,
	],
	exports: [
		RequestDemoDialogComponent,
	],
	entryComponents: [
		RequestDemoDialogComponent,
	],
})
export class CommonComponentsModule {}
