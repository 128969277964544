import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstablishmentComponent } from './establishment/establishment.component';
import { CoreValueComponent } from './core-value/core-value.component';
import { OurTeamComponent } from './our-team/our-team.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    EstablishmentComponent, 
    CoreValueComponent, 
    OurTeamComponent
  ],
  declarations: [EstablishmentComponent, CoreValueComponent, OurTeamComponent]
})
export class AboutUsModule { }
