import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';

@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.css']
})

export class ProfileEditorComponent implements OnInit {

    profileForm = this.fb.group({
      firstName: ['',Validators.required],
      lastName: [''],
      address: this.fb.group({
        street: [''],
        city: [''],
        state: [''],
        zip: ['']
      }),
    });

    clickMessage = '';

    onClickMe() {
      this.clickMessage = 'Thank you for submitting';
    }
    constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

}
