import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsModule } from '../about-us/about-us.module'; 
import { PricingModule } from '../pricing/pricing.module';
import { HomeModule } from '../home/home.module';

import { EstablishmentComponent } from '../about-us/establishment/establishment.component';
import { CoreValueComponent } from '../about-us/core-value/core-value.component';
import { OurTeamComponent } from '../about-us/our-team/our-team.component';
import { PricingComponent } from '../pricing/pricing.component';
import { HomeComponent } from '../home/home.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';


const appRoutes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'establishment', component: EstablishmentComponent },
  { path: 'coreValue', component: CoreValueComponent },
  { path: 'ourTeam', component: OurTeamComponent },
  { path: 'pricing', component: PricingComponent},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    CommonModule,
    AboutUsModule,
    PricingModule,
    HomeModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ],
  declarations: [PageNotFoundComponent]
})
export class AppRoutingModule { }
