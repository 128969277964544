import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl,FormGroup } from '@angular/forms';
@Component({
	styleUrls: ['./request-demo-dialog.component.css'],
	templateUrl: './request-demo-dialog.component.html',
	selector: 'app-request-demo-dialog'
})
export class RequestDemoDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<RequestDemoDialogComponent>
	) {}

	public cancel(): void {
		this.dialogRef.close();
	}
}
