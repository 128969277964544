import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-core-value',
  templateUrl: './core-value.component.html',
  styleUrls: ['./core-value.component.css']
})
export class CoreValueComponent implements OnInit, OnDestroy {
  public chosenLetter: Letter = {
    title: null,
    shortDescription: null,
    description:null
  };
  public isVSelected: boolean = false;
  public isESelected: boolean = false;
  public isCSelected: boolean = false;
  public isTSelected: boolean = false;
  public isOSelected: boolean = false;
  public isRSelected: boolean = false;

  constructor() { } // Constructor needed when we want to inject service

  private resetState() {
    this.chosenLetter.title = null;
    this.chosenLetter.shortDescription = null;
    this.chosenLetter.description = null;
    this.isVSelected = false;
    this.isESelected = false;
    this.isCSelected = false;
    this.isTSelected = false;
    this.isOSelected = false;
    this.isRSelected = false;
  }

  public onLetterClick(letter: string) {
    this.resetState();
    switch (letter) {
      case 'v':
        this.chosenLetter.title = 'Velocity';
        this.chosenLetter.shortDescription = '\"Speed that matters for our customer and company\"';
        this.chosenLetter.description = 'We play to win and to win we must be fast and first. Being able to make decision swiftly based on the best fact and information available and execute it efficiently without compromising the quality of work.';
        this.isVSelected = true;
        break;
      case 'e':
        this.chosenLetter.title = 'Excellence';
        this.chosenLetter.shortDescription = '\"Customer\'s satisfaction is our reward\"';
        this.chosenLetter.description ='In order to gain positive satisfaction, we often challenge ourself to create quality of services of unmatched value. We learn from mistakes and teach every employee to be the best of the best.';
        this.isESelected = true;
        break;
      case 'c':
        this.chosenLetter.title = 'Courage';
        this.chosenLetter.shortDescription = '\"Your problem is our problem\"';
        this.chosenLetter.description ='We believe that problems is a valuable experience for us in order to success. To solve the problem, we must have the courage to face it. This also implies to our co-workers.';
        this.isCSelected = true;
        break;
      case 't':
        this.chosenLetter.title = 'Technopile';
        this.chosenLetter.shortDescription = '\"Where business is not just what we value\"';
        this.chosenLetter.description ='We believe that being updated as much as possible in technological world is what makes us special. Thus, we see technology as core to our organization function.';
        this.isTSelected = true;
        break;
      case 'o':
        this.chosenLetter.title = 'Openess';
        this.chosenLetter.shortDescription = '\"When someone speaks up, we listen\"';
        this.chosenLetter.description ='Transparency is the way we show how we value both internal and external collaborators.';
        this.isOSelected = true;
        break;
      case 'r':
        this.chosenLetter.title = 'Responsiveness';
        this.chosenLetter.shortDescription = '\"Name your thoughts, we make sure it happen\"';
        this.chosenLetter.description ='Being able to respond to change is one of the way we learn and also the way we show values to customers.';
        this.isRSelected = true;
        break;
    }
  }

  ngOnInit() {
    this.chosenLetter.title = 'Velocity';
    this.chosenLetter.shortDescription = '\"Speed that matters for our customer and company\"';
    this.chosenLetter.description = 'We play to win and to win we must be fast and first. Being able to make decision swiftly based on the best fact and information available and execute it efficiently without compromising the quality of work.';
    this.isVSelected = true;
  }
  ngOnDestroy() {

  }
}

interface Letter {
  title: string;
  shortDescription: string;
  description:string;
}
