import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RequestDemoDialogComponent } from '../common-components/request-demo-dialog.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  public openRequestDemoDialog(): void {
		this.dialog.open(RequestDemoDialogComponent, {
      width: '500px',
      height: '500px'
		});
  }

}
